.loading__wrapper {
  justify-content: center;
  display: flex;
  z-index: 99999;
}

.loading__wrapper:after {
  content: '';
  border: solid 10px lightgray;
  border-top: solid 10px color('primary');
  border-radius: 100%;
  height: 100px;
  width: 100px;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
